import {Link} from "gatsby";
import styled from "styled-components";
import {Secondary} from "../../components/layouts/Secondary/Secondary";

const Wrapper = styled.section`
  padding: 100px 11.32%;
  @media (max-width: 599px) {
    padding: 50px 8.32%;
  }
  main {
    li {
      list-style: auto;
      margin-top: 15px;
      font-size: 1.25rem;
      line-height: 34px;
      @media (max-width: 599px) {
        font-size: 16px;
        line-height: 27px;
      }
    }
    .list-style-none li {
      list-style: none;
    }
    .padding-none {
      padding: 0;
    }
    .margin-none {
      margin: 0;
    }
    .ul-li-margin-none {
      li {
        margin: 0;
      }
    }
    .line-height-normal {
      line-height: normal;
    }
    ol.list-style-lower-roman li {
      list-style: lower-roman;
    }
    h2 {
      margin-top: 30px;
      font-size: 1.6rem;
      @media (max-width: 599px) {
        font-size: 1.3rem;
        line-height: 1.6;
      }
    }
    p {
      margin-top: 10px;
      text-align: left;
    }
    .ol-left-padding {
      padding-left: 90px;
    }
    .underline-text {
      text-decoration: underline;
    }
  }
`;

const TermsAndConditions = () => (
  <Secondary>
    <Wrapper>
      <main>
        <h2 className="last-updated">Last updated: June 28, 2022</h2>
        <h2>Making a complaint to Flahmingo</h2>
        <p>
          At Flahmingo, we take your complaints seriously and are committed to
          helping resolve your complaint about Flahmingo or the service you’ve
          received. The best way to address your complaint is to raise the issue
          as soon as it comes up.
        </p>
        <p>
          While we welcome any positive comments you have, it is just as
          important for us to know when you have a concern so we can promptly
          resolve it and retain your confidence. At the same time, your feedback
          helps us improve the quality of the products and services we offer to
          you and other customers.
        </p>
        <p>
          Should you have any questions or complaints about Flahmingo, please
          let us know. There are a variety of ways for you to provide feedback
          or express your concerns about your experiences with Flahmingo. We
          encourage you to get in touch with us by email, or via our chatbot.
        </p>
        <h2>1. Resolving complaints</h2>
        <div className="left-indentation">
          <p>
            At Flahmingo, we take your complaints seriously and are committed to
            helping resolve your complaint about the service you’ve received.
          </p>
          <p>To help reach a resolution, please follow these three steps:</p>
          <ol className="list-style-none padding-none">
            <li>
              <p className="underline-text">Step 1: Voice your complaint</p>
              <p>
                Please contact us via the Chatbot available at{" "}
                <Link to="/">www.flahmingo.com.</Link>
              </p>
              <p>
                If you are not satisfied with the resolution provided in Step 1,
                please proceed to Step 2.
              </p>
            </li>
            <li>
              <p className="underline-text">Step 2: Escalate your complaint</p>
              <p>
                You can escalate your complaint directly via email at{" "}
                <a href="mailto:compliance@flahmingo.com">
                  compliance@flahmingo.com
                </a>
                .
              </p>
              <p>
                Our Chief Compliance Officer will respond in writing and provide
                a summary of your complaint, our findings, and our proposed
                solution to address your concerns. The response will contain a
                reminder about escalation options available to you, including
                Step 3 should your concerns not be addressed to your
                satisfaction.
              </p>
            </li>
            <li>
              <p className="underline-text">Step 3: Additional steps</p>
              <p>
                If you are not satisfied with the resolution provided in Step 2
                or have not heard from us within 90 days, you can proceed to
                escalate your concern to the Ombudsman for Banking Services and
                Investments (OBSI). If Flahmingo anticipates the final response
                to take longer than 90 days you will be informed of the reasons
                and the expected new response times. The services of the OBSI
                are independent of Flahmingo and free to you.
              </p>
            </li>
          </ol>
        </div>
        <h2>Ombudsman for Banking Services and Investments (OBSI)</h2>
        <ul className="list-style-none padding-none ul-li-margin-none">
          <li>
            Website:{" "}
            <a target="_blank" href="http://www.obsi.ca">
              www.obsi.ca
            </a>
          </li>
          <li>
            Phone: <a href="tel:1-888-451-4519">1-888-451-4519</a>
          </li>
          <li>
            Email: <a href="mailto:ombudsman@obsi.ca">ombudsman@obsi.ca</a>
          </li>
        </ul>
        <p>
          A step-by-step process of how the OBSI complaint process works can be
          found at:
        </p>
        <p className="margin-none line-height-normal">
          {" "}
          <a
            href="https://www.obsi.ca/en/consumer-complaint-process.aspx"
            target="_blank"
          >
            https://www.obsi.ca/en/consumer-complaint-process.aspx
          </a>
          .
        </p>
        <p>To make a complaint to the Alberta Securities Commission: </p>
        <p className="margin-none line-height-normal">
          {" "}
          <a
            href="https://www.albertasecurities.com/enforcement/file-a-complaint"
            target="_blank"
          >
            https://www.albertasecurities.com/enforcement/file-a-complaint
          </a>
        </p>
      </main>
    </Wrapper>
  </Secondary>
);
export default TermsAndConditions;